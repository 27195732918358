import React from "react"
import Layout from '../components/layout'
import Footer from '../components/footer'
import styled from 'styled-components'
import Topo from '../styles/topo'

const Main = styled.main`
padding: 4vh 7vw 0;
margin-top: -9vh;
background: #fff;
border-top: 3px solid #ffce4b;
`

const Content = styled.div`
max-width: 1040px;
margin-left: auto;
margin-right: auto;
`

const Heading = styled.h1`
text-align: center;
padding-bottom: 9vh;
margin-top: 5vh;`

export default function About() {
    return (
        <Layout>
        <Topo><Heading>About this site</Heading></Topo>
      <Content>
      <Main>
        <p>Hi! I'm <a href="https://www.jamiemchale.com">Jamie McHale</a>, and I run this site as a side-project.</p>

        <p>I know how tough it can be working as a freelancer or entrepreneur. I've seen friends deal with stress, anxiety and burnout. I've experienced each of those too! I started gathering resources to help others deal with the mental health challenges and opportunities that being a solo worker bring.</p>

        <p>I hope that this site can be a useful starting point for all kinds of self-directed workers. It's not just the downsides to solo work, but the opportunities to create positive and healthy habits, alongside meaningful work, and a supportive community.</p>

        <p>If you want to follow along with new announcements then please do follow <a href="https://twitter.com/healthsolo">@healthsolo</a> on Twitter, or contact me directly at <a href="https://twitter.com/jamiemchale">@jamiemchale</a></p>
      </Main>
      </Content>
      <Footer />
    </Layout>
    )
}